import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ConsultActions } from '../store/ducks/Consult';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { QRCodeCanvas } from 'qrcode.react';

function formatNumber(value) {
  const result = (value * 0.293).toString();
  const hasIndex = result.includes('.');
  let formatValue = null;

  if (hasIndex) {
    const index = result.indexOf('.');
    const format = result.slice(0, index + 3);
    const formatNumber = format.slice(index + 1).length;
    formatNumber === 1
      ? (formatValue = format.concat('0'))
      : (formatValue = format);
  } else {
    formatValue = result;
  }

  return formatValue;
}

function Declaration() {
  const history = useHistory();
  const { hash } = useParams();
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [gasrec, setGasrec] = useState('');
  const { data, loading, error } = useSelector(({ consult }) => consult);

  const qrRef = useRef();

  useEffect(() => {
    dispatch(ConsultActions.getConsult(hash));
  }, [hash, dispatch]);

  useEffect(() => {
    setGasrec(data);

    if (data) {
      setCode(data.declaration_final?.slice(0, 4));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        history.push('/users/historic/traded');
      }, 2000);
    }
  }, [error, history]);

  const useStyles = makeStyles({
    table: {
      width: '795px',
      border: '1px solid',
    },
    th: {
      fontWeight: '500',
      backgroundColor: 'rgba(141, 179, 139, 0.2)',
    },
    title: {
      fontWeight: '500',
    },
    div: {
      display: 'block',
    },
    container: {
      overflow: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'rgb(255, 255, 255)',
    },
  });

  const styleClass = useStyles();

  let BeneficiaryRows = [];
  let DeviceRows = [];
  let DeclarationRows = [];
  let hintIndex = 0;

  if (gasrec) {
    BeneficiaryRows = [
      { name: 'Beneficiary', data: gasrec.client.name },
      { name: "Beneficiary's Location", data: gasrec.client.localization },
      { name: "Beneficiary's Country", data: gasrec.client.country },
      {
        name: 'Redemption Date',
        data: moment(gasrec.created_at).format('DD/MM/YYYY'),
      },
      {
        name: 'Period of Consumption Report',
        data:
          moment(gasrec.date_initial).format('DD/MM/YYYY') +
          ' | ' +
          moment(gasrec.date_final).format('DD/MM/YYYY'),
      },
      {
        name: 'Redeemed GAS-REC Number',
        data: gasrec.volume + ' MMBTU',
      },
      {
        name: 'Equivalent in MWh',
        data: formatNumber(gasrec.volume),
      },
      {
        name: 'Equivalent in m3',
        data: gasrec.business.source_id == 1 ? 'Not applicable' : (gasrec.volume * 26.8).toFixed(2), // TODO: Se a empresa der OK, vira 26.8081
      },
      {
        name: 'Reason for Redemption',
        data: gasrec?.reason_retirement,
      },
    ];

    DeviceRows = [
      {
        name: 'Device',
        data: gasrec.business.social_name + ' | ' + `GAS-REC-${code}`,
        hint: '',
      },
      {
        name: 'Country of production',
        data: gasrec.business.country,
        hint: '',
      },
      {
        name: 'Substrate',
        data: gasrec.business.substrate,
        hint: '',
      },
      {
        name: 'Biogas application',
        data: gasrec.business.biogas_aplication,
        hint: '',
      },
      {
        name: 'Use of Biogas or Biomethane',
        data: gasrec.business.biogas_utility,
        hint: '',
      },
      {
        name: 'Detailing',
        data: gasrec.business.biogas_details,
        hint: '',
      },
      {
        name: 'Avoided Methane', // DONE: Tranformar em referência e mandar o HINT
        data: gasrec.business.emission_factor_avoided_methane
          ? gasrec.business.avoided_methane || 'Not applicable'
          : 'Not considered in the certificate',
        // DONE: Para baixo da tabela, antes de Retired Certificates
        hint:
          'This information refers to the calculation of the volume of methane that was no longer emitted into the atmosphere due to this project.',
      },
      {
        name: 'Avoided Methane Reference',
        data: gasrec.business.reference_methane || 'Not applicable',
        hint: '',
      },
      {
        name: 'Emission Reduction', // DONE: Exibir de acordo com o ultimo campo da tabela (business)
        data: gasrec.business.emission_factor_reduction
          ? gasrec.business.emission_reduction || 'Not applicable'
          : 'Not considered in the certificate',
        hint:
          'This information represents the volume of CO2 equivalent that was prevented from being emitted into the atmosphere.',
      },
      {
        name: 'Emission Reduction Reference',
        data: gasrec.business.reference_reduction || 'Not applicable',
        hint: '',
      },
      {
        name: 'Commissioning Date',
        data: moment(gasrec.business.comercial_operation_at).format(
          'DD/MM/YYYY'
        ),
        hint: '',
      },
      {
        name: 'Emission Factor Considering Life Cycle (tCO2eq/MMBTU)',
        data: gasrec.business.carbon || 'Not applicable',
        hint:
          'This information refers to the total CO2 equivalent over the life cycle of biogas or biomethane production and transportation, excluding CO2 emissions from burning or usage.',
      },
      {
        name: 'Reference', // DONE: Aumentar de 255 para 1034 / Text e usar o texto do Luciano
        data: gasrec.business.carbon_reference || 'Not applicable',
        hint: '',
      },
      {
        name: 'Carbon emissions (tCO2eq/MMBTU) - Biogas/Biomethane Burning',
        data: gasrec.business.burned_carbon,
        hint:
          'This information refers exclusively to the burning factor of biogas or biomethane, taking into account the relevant greenhouse gases and converting them into CO2 equivalent.',
      },
      {
        name: 'Reference',
        data: gasrec.business.burned_carbon_reference,
        hint: '',
      },
      {
        name: 'Biogenic (tCO2/MMBTU) - Biogas/Biomethane Burning',
        data: gasrec.business.co2_biogenic_mmbtu,
        hint:
          'This information refers exclusively to the volume of biogenic CO2 emitted during gas burning.',
      },
      {
        name: 'Reference',
        data: gasrec.business.co2_biogenic_mmbtu_reference,
        hint: '',
      },
      {
        name: 'Carbon Credit',
        data: gasrec.business.is_carbon_credit == '1' ? 'Yes' : 'Not',
        hint: '',
      },
      {
        name: 'Carbon Credit Start Date',
        data:
          gasrec.business.start_date_carbon_credit != null
            ? moment(gasrec.business.start_date_carbon_credit).format(
                'DD/MM/YYYY'
              )
            : 'Not applicable',
        hint: '',
      },
      {
        name: 'Carbon Credit Expiration Date',
        data:
          gasrec.business.expiration_date_carbon_credit != null
            ? moment(gasrec.business.expiration_date_carbon_credit).format(
                'DD/MM/YYYY'
              )
            : 'Not applicable',
        hint: '',
      },
      {
        name: 'Carbon Credit Methodology',
        data:
          gasrec.business.methodology_carbon_credit != null
            ? gasrec.business.methodology_carbon_credit
            : 'Not applicable',
        hint: '',
      },
      {
        name: 'Unit issues CBIO from Renovabio',
        data: gasrec.business.issue_cbio == '1' ? 'Yes' : 'Not',
        hint: '',
      },
      {
        name: 'CBIO issuance start date',
        data:
          gasrec.business.start_date_cbio != null
            ? moment(gasrec.business.start_date_cbio).format('DD/MM/YYYY')
            : 'Not applicable',
        hint: '',
      },
      {
        name: 'CBIO issuance expiration date',
        data:
          gasrec.business.expiration_date_cbio != null
            ? moment(gasrec.business.expiration_date_cbio).format('DD/MM/YYYY')
            : 'Not applicable',
        hint: '',
      },
    ];

    DeviceRows.forEach(function (deviceRow, index) {
      if (deviceRow.hint && deviceRow.hint.length > 0) {
        hintIndex++;
        deviceRow.hint = `#${hintIndex} ${deviceRow.hint}`;
        deviceRow.name = `${deviceRow.name} #${hintIndex}`;
      }
    });

    DeclarationRows = [
      {
        declaration_initial: gasrec.declaration_initial,
        declaration_final: gasrec.declaration_final,
        volume: gasrec.volume,
        first_period: gasrec.recEmission.first_period,
        last_period: gasrec.recEmission.last_period,
      },
    ];
  }

  // ----------------------------------------------
  // PDF GENERATION USING jsPDF + autoTable
  // ----------------------------------------------
  const handleDownloadPdf = () => {
    // Create a new jsPDF instance (portrait, points, A4)
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4',
    });
    const totalPages = 2; // Example: adjust as needed based on your content

    // We'll define some top margin positions so we can move sequentially down the page.
    let cursorY = 50;

    let img = new Image();
    img.src = '/assets/retirement_logo.png';
    doc.addImage(img, 'png', 244, 20, 90, 90);

    cursorY += 80;

    // TITLE
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text(
      'GAS-REC Redemption Statement',
      doc.internal.pageSize.getWidth() / 2,
      cursorY,
      { align: 'center' }
    );
    cursorY += 50;

    // PARAGRAPH: statement explanation
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    const statementText = `This statement confirms that the following GAS-RECS have been withdrawn from the market and are therefore no longer transferable or assignable.`;
    // Split text if needed (so it doesn't go off-page width)
    let lines = doc.splitTextToSize(statementText, 500);
    doc.text(lines, 40, cursorY);
    cursorY += 60;

    // BENEFICIARY DATA TABLE
    if (BeneficiaryRows.length > 0) {
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text(
        'Beneficiary Data',
        doc.internal.pageSize.getWidth() / 2,
        cursorY,
        { align: 'center' }
      );
      cursorY += 20;

      // Prepare data for autoTable
      const benHead = [['Information', 'Description']];
      const benBody = BeneficiaryRows.map((row) => [row.name, row.data]);

      doc.autoTable({
        startY: cursorY,
        head: benHead,
        body: benBody,
        margin: { left: 40, right: 40 },
        styles: { fontSize: 10, cellPadding: 3 },
        headStyles: {
          fillColor: [141, 179, 139],
          textColor: 50,
          halign: 'center',
        },
        columnStyles: { 0: { halign: 'top', cellWidth: 160 } },
      });

      cursorY = doc.lastAutoTable.finalY + 40;
    }

    // DEVICE DATA TABLE
    if (DeviceRows.length > 0) {
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text(
        'Device Details',
        doc.internal.pageSize.getWidth() / 2,
        cursorY,
        {
          align: 'center',
        }
      );
      cursorY += 20;

      // Prepare data for autoTable
      const devHead = [['Information', 'Description']];
      const devBody = DeviceRows.map((row) => [row.name, row.data]);

      doc.autoTable({
        startY: cursorY,
        head: devHead,
        body: devBody,
        margin: { left: 40, right: 40 },
        styles: { fontSize: 9, cellPadding: 3 },
        headStyles: {
          fillColor: [141, 179, 139],
          textColor: 50,
          halign: 'center',
        },
        columnStyles: { 0: { halign: 'top', cellWidth: 160 } },
        // If a row is too tall, autoTable will automatically add pages
        didDrawPage: (data) => {
          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();
          const pageNumberText = `${doc.internal.getNumberOfPages()}/${totalPages}`;
          doc.setFontSize(9);
          doc.text(pageNumberText, pageWidth - 30, pageHeight - 20);
        },
      });

      cursorY = doc.lastAutoTable.finalY + 10;
    }

    // HINTS
    const hints = DeviceRows.filter((row) => row.hint && row.hint.length > 0);
    if (hints.length > 0) {
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');

      doc.autoTable({
        startY: cursorY,
        body: hints.map((row) => [row.hint]),
        margin: { left: 40, right: 40 },
        styles: { fontSize: 9, cellPadding: 3 },
        columnStyles: { 0: { halign: 'top', fillColor: 'white' } },
        // If a row is too tall, autoTable will automatically add pages
      });

      cursorY = doc.lastAutoTable.finalY + 30;
    } else {
      cursorY += 20;
    }

    // RETIRED CERTIFICATES TABLE
    if (DeclarationRows.length > 0) {
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text(
        'Retired Certificates',
        doc.internal.pageSize.getWidth() / 2,
        cursorY,
        { align: 'center' }
      );
      cursorY += 20;

      // Prepare data for autoTable
      const decHead = [
        [
          'ID Certificate',
          'For Certificate ID',
          'Number of Certificates',
          'Production period',
        ],
      ];
      const decBody = DeclarationRows.map((row) => [
        splitText(row.declaration_initial, 20), // Force split for first cell
        splitText(row.declaration_final, 20), // Force split for second cell
        row.volume,
        `${moment(row.first_period, 'DD/MM/YYYY').format(
          'DD/MM/YYYY'
        )} - ${moment(row.last_period, 'DD/MM/YYYY').format('DD/MM/YYYY')}`,
      ]);

      doc.autoTable({
        startY: cursorY,
        head: decHead,
        body: decBody,
        margin: { left: 40, right: 40 },
        styles: {
          fontSize: 10,
          cellPadding: 3,
          overflow: 'visible', // Allow content overflow to break
          halign: 'center', // Center content horizontally
        },
        headStyles: {
          fillColor: [141, 179, 139],
          textColor: 50,
          halign: 'center', // Center content horizontally
        },
      });

      cursorY = doc.lastAutoTable.finalY + 30;
    }

    // STATEMENT ON FOOTER
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor('#464646');
    const statementOnFooterText =
      'The owner of the device retains the rights to issue and trade the carbon credits mentioned in the declaration and the beneficiary cannot claim any right to the carbon credits or offsettings mentioned in the declaration.\n\nThis document is proof that the Gas Certificates have been retired for the referred beneficiary and can no longer be transacted with other beneficiaries. The Totum Institute is the auditor responsible for the Brazilian Renewable Gas Certificates (GAS-REC) program. The Totum Institute is not responsible for the application of the declaration issued in the name of the beneficiary. This declaration does not prove that the GAS-REC emitting device meets sustainability criteria or other applicable standards for the same type of device; it only certifies the actual generation of Biogas.\n\nGAS-REC emissions for biomethane up to 2023 used a conversion factor of 26.8 m3/MMBTU. As of 2024, GAS-REC emissions for biomethane began to use the factor of 26.8081 m3/MMBTU, in line with the commercial practices of Registrants (https://www.gov.br/anp/pt-br/assuntos/movimentacao-estocagem-e-comercializacao-de-gas-natural/acompanhamento-do-mercado-de-gas-natural/acompanhamento-do-mercado-de-gas-natural/cartilha-ranp52-2011-atualizada-08-2022.pdf).\n\nThe volume used for Mwh is 0.293/MMBTU.';
    // Split text if needed (so it doesn't go off-page width)
    let linesOnFooter = doc.splitTextToSize(statementOnFooterText, 500);
    doc.text(linesOnFooter, 40, cursorY);
    cursorY += 30;

    cursorY += 180;

    const qrCodeCanvas = qrRef.current.querySelector('canvas');
    if (qrCodeCanvas) {
      const qrCodeDataURL = qrCodeCanvas.toDataURL('image/png');
      doc.addImage(qrCodeDataURL, 'PNG', 500, cursorY, 50, 50);
    }

    console.log(gasrec);

    //

    // Finally, save the PDF
    const dateFormat = 'YYYY-MM-DD';
    let filename = `redemption_${sanitizeName(gasrec.client.name)}_${moment(
      gasrec.date_initial
    ).format(dateFormat)}_${moment(gasrec.date_final).format(
      dateFormat
    )}_${hash}`;

    // Limit to 255 characters
    if (filename.length > 251) {
      filename = filename.substring(0, 251);
    }

    filename += '.pdf';

    doc.save(filename);
  };

  // Function to split text into lines of a given length
  function splitText(text, maxLength) {
    const regex = new RegExp(`(.{1,${maxLength}})`, 'g');
    return text.match(regex).join('\n');
  }

  function sanitizeName(str) {
    // 1. Trim the string
    str = str.trim();

    // 2. Remove diacritics (accents)
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // 3. Convert to lowercase
    str = str.toLowerCase();

    // 4. Replace non-alphanumeric characters (except spaces) with underscores
    str = str.replace(/[^a-z0-9\s]/g, '_');

    // 5. Replace spaces with underscores
    str = str.replace(/\s+/g, '_');

    // 6. Collapse multiple underscores into just one
    str = str.replace(/_+/g, '_');

    return str;
  }

  return (
    <>
      {gasrec ? (
        <>
          {/* Button to trigger the PDF generation */}
          <div
            style={{ textAlign: 'center', margin: '20px' }}
            className="hide-on-mobile"
          >
            <button onClick={handleDownloadPdf}>Download as PDF</button>
          </div>

          {/* We keep the existing on-screen layout so users can still see everything */}
          <div id="pdf-content" className={styleClass.container}>
            <img src="/assets/retirement_logo.png" height="120" />
            <h1
              className={styleClass.title}
              style={{
                textAlign: 'center',
                marginBottom: '50px',
                fontSize: '30px',
                textTransform: 'uppercase',
              }}
            >
              GAS-REC Redemption Statement
            </h1>
            <div style={{ marginBottom: '30px', maxWidth: '795px' }}>
              <h4>
                This statement confirms that the following GAS-RECS have been
                withdrawn from the market and are therefore no longer
                transferable or assignable.
              </h4>
            </div>

            <div style={{ marginBottom: '50px' }}>
              <h2 style={{ textAlign: 'center' }} className={styleClass.title}>
                Beneficiary Data
              </h2>
              <TableContainer component={Paper}>
                <Table
                  className={styleClass.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    {BeneficiaryRows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell
                          className={styleClass.th}
                          style={{
                            borderRight: '2px solid rgba(224, 224, 224, 1)',
                            width: '270px',
                          }}
                          component="th"
                          scope="row"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.data}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div style={{ marginBottom: '50px' }}>
              <h2 className={styleClass.title} style={{ textAlign: 'center' }}>
                Device Details
              </h2>
              <TableContainer component={Paper}>
                <Table className={styleClass.table} size="small">
                  <TableBody>
                    {DeviceRows.map((row) => (
                      <React.Fragment key={`${row.name}-${Math.random()}`}>
                        <TableRow>
                          <TableCell
                            className={styleClass.th}
                            style={{
                              borderRight: '2px solid rgba(224, 224, 224, 1)',
                              width: '270px',
                            }}
                            component="th"
                            scope="row"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.data}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {hintIndex > 0 && (
                <div style={{ marginTop: '30px', maxWidth: '795px' }}>
                  {DeviceRows.map(
                    (row) =>
                      row.hint &&
                      row.hint.length > 0 && (
                        <p style={{ textAlign: 'justify' }}>{row.hint}</p>
                      )
                  )}
                </div>
              )}
            </div>

            <div style={{ marginBottom: '50px' }}>
              <h2 className={styleClass.title} style={{ textAlign: 'center' }}>
                Retired Certificates
              </h2>
              <TableContainer component={Paper}>
                <Table
                  className={styleClass.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={styleClass.th}>
                        ID Certificate
                      </TableCell>
                      <TableCell align="center" className={styleClass.th}>
                        For Certificate ID
                      </TableCell>
                      <TableCell align="center" className={styleClass.th}>
                        Number of Certificates
                      </TableCell>
                      <TableCell align="center" className={styleClass.th}>
                        Production period
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {DeclarationRows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" component="th" scope="row">
                          {row.declaration_initial}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.declaration_final}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.volume}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {moment(row.first_period, 'DD/MM/YYYY').format(
                            'DD/MM/YYYY'
                          )}
                          <TableCell
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              borderBottom: '0',
                            }}
                          >
                            {moment(row.last_period, 'DD/MM/YYYY').format(
                              'DD/MM/YYYY'
                            )}
                          </TableCell>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div
              style={{
                maxWidth: '795px',
                textAlign: 'justify',
                marginBottom: '50px',
              }}
            >
              The owner of the device retains the rights to issue and trade the
              carbon credits mentioned in the declaration and the beneficiary
              cannot claim any right to the carbon credits or offsettings
              mentioned in the declaration.
              <br />
              <br />
              This document is proof that the Gas Certificates have been retired
              for the referred beneficiary and can no longer be transacted with
              other beneficiaries. The Totum Institute is the auditor
              responsible for the Brazilian Renewable Gas Certificates (GAS-REC)
              program. The Totum Institute is not responsible for the
              application of the declaration issued in the name of the
              beneficiary. This declaration does not prove that the GAS-REC
              emitting device meets sustainability criteria or other applicable
              standards for the same type of device; it only certifies the
              actual generation of Biogas.
              <br />
              <br />
              GAS-REC emissions for biomethane up to 2023 used a conversion
              factor of 26.8 m3/MMBTU. As of 2024, GAS-REC emissions for
              biomethane began to use the factor of 26.8081 m3/MMBTU, in line
              with the commercial practices of Registrants
              (https://www.gov.br/anp/pt-br/assuntos/movimentacao-estocagem-e-comercializacao-de-gas-natural/acompanhamento-do-mercado-de-gas-natural/acompanhamento-do-mercado-de-gas-natural/cartilha-ranp52-2011-atualizada-08-2022.pdf).
              <br />
              <br />
              The volume used for Mwh is 0.293/MMBTU.
            </div>

            <div
              ref={qrRef}
              style={{
                width: '795px',
                marginBottom: '50px',
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <QRCodeCanvas
                value={`https://sisgasrec.institutototum.com.br/declaration/${gasrec.declaration_hash}`}
                size={100}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={styleClass.container}>
          <h1
            className={styleClass.title}
            style={{
              textAlign: 'center',
              margin: '50px 50px',
              fontSize: '30px',
              textTransform: 'uppercase',
              alignSelf: 'center',
            }}
          >
            Non-existent statement
          </h1>
        </div>
      )}
    </>
  );
}

export default Declaration;
